interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const HeroImage = (props: SVGProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 539 415"
		fill="none"
		{...props}
	>
		<g clipPath="url(#a)">
			<path
				fill="#F6F1D1"
				d="M104.678 185.491c4.558-15.064-3.947-30.975-18.996-35.538-15.049-4.563-30.943 3.951-35.501 19.015-4.558 15.065 3.947 30.976 18.996 35.538 15.049 4.563 30.943-3.95 35.501-19.015ZM422.003 293.185c64.468-64.535 64.468-169.165 0-233.7-64.468-64.534-168.991-64.534-233.46 0-64.467 64.535-64.467 169.165 0 233.7 64.469 64.534 168.992 64.534 233.46 0Z"
			/>
			<path
				fill="#fff"
				d="M60.2 367.756h169.158l8.054 46.191H58.199l2-46.191Z"
			/>
			<path
				fill="#000"
				d="M237.412 415c-.513 0-.974-.359-1.051-.899l-7.9-45.292H61.225l-1.949 45.138a1.085 1.085 0 0 1-1.129 1.027c-.59 0-1.051-.539-1.025-1.129l1.975-46.166c0-.565.487-1.027 1.077-1.027h169.184c.513 0 .975.385 1.052.899l8.054 46.165a1.08 1.08 0 0 1-.872 1.258h-.18V415Z"
			/>
			<path
				className="fill-[#383D3B] dark:fill-muted-foreground"
				fill="#383D3B"
				d="m346.449 243.664-50.786-16.638s-18.955 15.278-24.804 30.17c0 0-16.364-30.863-36.012-52.687-12.593-13.968-39.885-30.349-71.459-28.475l-42.758.565c-26.137 0-44.066 4.545-63.714 30.862-19.647 26.318-31.061 45.19-48.631 77.388-17.57 32.197-4.515 98.775 56.557 98.775 18.263 0 31.42-6.599 40.886-15.868h123.579l-2.847-17.511c17.031 23.981 66.33 44.009 93.903 12.248 28.497-32.789 26.06-118.829 26.06-118.829h.026Z"
			/>
			<path
				fill="#fff"
				d="m163.388 176.059-42.758.565c-5.361 0-10.363.206-15.108.719 12.492 15.123 43.143 41.647 81.258.539-7.387-1.592-15.236-2.336-23.392-1.848v.025Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M153.718 414.589c-.59 0-1.077-.488-1.077-1.079v-.898c0-9.192 4.488-17.819 12.029-23.057.488-.334 1.154-.231 1.488.257.333.487.231 1.155-.282 1.489a25.996 25.996 0 0 0-11.107 21.311v.898c0 .591-.487 1.079-1.077 1.079h.026ZM64.868 384.702c-30.6 0-46.554-16.073-54.531-29.578-12.543-21.183-13.8-50.941-2.976-70.789 16.852-30.862 28.446-50.35 48.709-77.515 19.648-26.318 37.833-31.299 64.56-31.299l42.732-.565c30.369-1.798 58.173 13.171 72.255 28.834 16.672 18.512 31.138 43.803 35.089 50.941 6.643-14.276 23.495-27.961 24.238-28.552a1.02 1.02 0 0 1 1.001-.18l50.786 16.638c.436.154.718.54.744 1.002.102 3.517 2.129 86.836-26.317 119.573-10.183 11.733-24.187 17.511-40.526 16.689-21.52-1.053-41.809-13.223-52.453-25.702l2.18 13.377c.051.309-.026.642-.231.873a1.129 1.129 0 0 1-.821.386H106.189c-10.926 10.527-24.829 15.867-41.321 15.867Zm103.496-207.744c-1.616 0-3.258.052-4.899.154l-42.809.565c-26.625 0-43.656 4.699-62.868 30.426-20.186 27.063-31.754 46.474-48.554 77.259-10.49 19.231-9.234 48.117 2.924 68.683 7.72 13.018 23.11 28.526 52.684 28.526 16.057 0 29.574-5.238 40.142-15.559.205-.206.461-.308.744-.308H228.05l-2.642-16.253c-.077-.488.18-.976.642-1.181.461-.18 1-.026 1.282.385 9.337 13.146 30.677 27.011 53.402 28.115 15.647.77 29.061-4.75 38.808-15.971 26.471-30.477 25.958-108.789 25.804-117.339l-49.504-16.227c-2.873 2.414-18.852 16.176-24.034 29.373a1.093 1.093 0 0 1-.949.694c-.41 0-.82-.206-1-.565-.154-.308-16.518-31.017-35.858-52.456-12.979-14.43-37.936-28.269-65.689-28.269l.052-.052ZM170.484 151.915c13.412-13.426 13.412-35.195 0-48.621-13.413-13.426-35.159-13.426-48.571 0-13.413 13.426-13.413 35.195 0 48.621 13.412 13.426 35.158 13.426 48.571 0Z"
			/>
			<path
				fill="#fff"
				d="M124.811 152.772c9.464-3.03 17.544-17.28 17.724-17.999 1.462-5.88-.949-23.186-.949-23.186s22.058-5.648 28.676-2.798c3.719 1.592 7.464 9.808 7.618 13.171.077 1.721 1.923 9.963 1.026 21.157-1.308 16.125-.513 26.19-17.596 26.19l-.179 10.347H123.99l.821-26.857v-.025Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M161.105 178.319c-.616 0-1.077-.513-1.077-1.104l.179-7.96c0-.59.488-1.052 1.077-1.052 14.698 0 15.236-7.6 16.237-21.388.077-1.233.179-2.491.282-3.8.872-10.99.231-19.642.231-19.745-.052-.591.384-1.104 1-1.156.59-.051 1.103.386 1.154 1.002 0 .077.667 8.909-.231 20.078-.102 1.31-.205 2.568-.282 3.775-1 13.788-1.667 22.98-17.339 23.391l-.154 6.932c0 .591-.487 1.053-1.077 1.053v-.026ZM123.99 178.319a1.057 1.057 0 0 1-1.077-1.104l.82-24.469c0-.591.565-1.053 1.103-1.027.59 0 1.052.513 1.026 1.104l-.82 24.469c0 .591-.488 1.027-1.078 1.027h.026Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M161.31 170.359c-9.182-.359-14.543-2.799-14.748-2.901a1.078 1.078 0 0 1-.513-1.438 1.076 1.076 0 0 1 1.436-.514c.051 0 5.233 2.388 13.902 2.696.59 0 1.052.514 1.026 1.13 0 .591-.487 1.027-1.077 1.027h-.026Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M161.105 173.39s-1.411 5.417-6.412 5.289c-5.002-.103-7.721-12.222-7.721-12.222l14.312 2.799-.179 4.108v.026Z"
			/>
			<path
				fill="#fff"
				d="M126.837 138.29c0 5.032 4.078 9.115 9.106 9.115 5.027 0 9.105-4.083 9.105-9.115 0-5.033-4.078-9.115-9.105-9.115-5.028 0-9.106 4.082-9.106 9.115Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M137.61 140.986c-.334 0-.667-.154-.872-.462l-3.335-4.673c-.333-.488-.23-1.156.257-1.49.487-.333 1.154-.231 1.488.257l3.334 4.673c.333.488.231 1.156-.257 1.489-.179.129-.41.206-.615.206ZM156.308 134.465c-.128 1.207-1.179 2.08-2.385 1.951-1.205-.128-2.078-1.181-1.949-2.388.128-1.206 1.18-2.079 2.385-1.951 1.206.128 2.078 1.181 1.949 2.388ZM173.135 134.465c-.129 1.207-1.18 2.08-2.386 1.951a2.168 2.168 0 0 1-1.949-2.388c.128-1.206 1.18-2.079 2.385-1.951a2.168 2.168 0 0 1 1.95 2.388ZM163.26 107.967c0 6.162 4.976 11.143 11.131 11.143 6.156 0 11.132-4.981 11.132-11.143 0-6.163-4.976-11.144-11.132-11.144-6.155 0-11.131 4.981-11.131 11.144Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M148.229 109.046c0 6.162 4.976 11.143 11.132 11.143s11.132-4.981 11.132-11.143c0-6.163-4.976-11.144-11.132-11.144s-11.132 4.981-11.132 11.144Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M134.506 110.354c0 5.418 4.386 9.834 9.824 9.834a9.813 9.813 0 0 0 9.824-9.834 9.813 9.813 0 0 0-9.824-9.833 9.813 9.813 0 0 0-9.824 9.833Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M135.199 96.156c0 5.418 4.386 9.834 9.824 9.834a9.813 9.813 0 0 0 9.823-9.834 9.813 9.813 0 0 0-9.823-9.834 9.813 9.813 0 0 0-9.824 9.834Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M149.973 96.156c0 6.162 4.976 11.143 11.132 11.143s11.132-4.981 11.132-11.143c0-6.162-4.976-11.143-11.132-11.143s-11.132 4.98-11.132 11.143Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M163.259 105.425c-6.72 0-12.209-5.469-12.209-12.222 0-6.753 5.464-12.222 12.209-12.222 6.746 0 12.21 5.47 12.21 12.222 0 6.753-5.464 12.222-12.21 12.222Zm0-22.287c-5.54 0-10.054 4.52-10.054 10.065 0 5.546 4.514 10.065 10.054 10.065 5.541 0 10.055-4.519 10.055-10.065 0-5.546-4.514-10.065-10.055-10.065ZM169.518 151.77c-.282 0-.59-.128-.795-.359a1.063 1.063 0 0 1 .077-1.515l3.591-3.287a1.06 1.06 0 0 1 1.513.077c.41.437.359 1.13-.077 1.515l-3.591 3.287a1.105 1.105 0 0 1-.718.282ZM168.338 146.558c-.539 0-1-.411-1.077-.95l-1.693-14.379c-.077-.59.359-1.129.949-1.207.59-.077 1.129.36 1.206.951l1.692 14.378c.077.591-.359 1.13-.949 1.207h-.128Z"
			/>
			<path
				fill="#fff"
				d="M105.753 368.834c-.308 0-.615-.128-.846-.385-.385-.462-.308-1.129.154-1.515 38.474-31.35 19.647-120.882 19.442-121.78a1.066 1.066 0 0 1 .821-1.284 1.064 1.064 0 0 1 1.282.821c.795 3.723 19.289 91.74-20.186 123.887a1.106 1.106 0 0 1-.667.231v.025ZM19.468 309.087c-.23 0-.487-.077-.667-.232-.462-.385-.538-1.052-.154-1.514.129-.154 12.8-15.894 24.496-28.321 20.878-22.184 30.676-40.26 30.779-40.44a1.086 1.086 0 0 1 1.462-.436c.513.282.718.924.436 1.464-.102.179-10.029 18.486-31.113 40.901-11.62 12.35-24.264 28.038-24.393 28.192a1.081 1.081 0 0 1-.846.411v-.025ZM226.742 352.941c-.513 0-.975-.385-1.051-.899-.334-2.028-8.208-49.708-8.875-55.871-1.95-18.229 1.667-56.41 1.718-56.795a1.075 1.075 0 0 1 1.18-.975c.59.051 1.026.59.975 1.181-.026.385-3.617 38.36-1.719 56.358.642 6.086 8.772 55.255 8.849 55.743.103.59-.307 1.129-.897 1.232h-.18v.026Z"
			/>
			<path
				fill="#fff"
				d="M102.367 177.702c11.697-12.709 36.269-21.259 60.456-16.971 13.749 2.439 21.956 8.55 19.52 17.408-1.975 7.138-11.03 5.418-22.187 3.492-1.334-.231-7.285-.462-7.285-.462 4.386 3.825 5.31 10.989.206 16.304.897 1.386 1.231 3.492.307 6.676-1.051 3.594-4.283 5.674-8.028 6.881 1.308 3.363 2.36 9.089-3.155 13.12-1.128.822-2.36 1.412-3.668 1.823 1.744 3.03 2.052 15.842-12.978 18.949l-4.181 19.462c-3.155 14.713-17.981 23.776-32.524 19.874-14.723-3.98-22.982-19.591-17.98-33.995 9.08-26.138 22.956-63.343 31.446-72.561h.051Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M95.775 286.26c-2.385 0-4.796-.308-7.181-.95-7.413-2.003-13.466-6.83-17.057-13.634-3.591-6.778-4.181-14.507-1.668-21.773 9.799-28.218 23.239-63.779 31.678-72.92 11.08-12.042 35.755-21.85 61.43-17.305 9.593 1.694 16.211 5.058 19.161 9.731 1.718 2.722 2.128 5.751 1.205 9.038-2.18 7.908-11.696 6.291-22.7 4.391l-.718-.129c-.667-.103-2.744-.231-4.566-.334a11.84 11.84 0 0 1 2.206 6.394c.154 3.183-1 6.316-3.232 8.884.821 1.771.821 4.056 0 6.829-.949 3.261-3.539 5.675-7.694 7.241 1.154 3.595 1.692 9.243-3.899 13.326a14.093 14.093 0 0 1-2.873 1.592c.693 2.233.821 6.059-.872 9.808-1.488 3.338-4.899 7.626-12.62 9.423l-4.027 18.795c-1.59 7.42-6.027 13.659-12.491 17.613-4.36 2.671-9.234 4.032-14.184 4.032l.102-.052Zm7.362-107.839c-6.387 6.933-17.468 32.557-31.241 72.175-2.309 6.676-1.77 13.814 1.513 20.053 3.309 6.265 8.875 10.707 15.698 12.556 6.745 1.823 13.722.821 19.673-2.825 5.95-3.646 10.029-9.397 11.491-16.227l4.181-19.462c.077-.411.41-.745.82-.822 7.311-1.515 10.44-5.366 11.774-8.344 1.744-3.903 1.18-7.806.487-9.013a1.158 1.158 0 0 1-.077-.924 1.1 1.1 0 0 1 .667-.642 12.475 12.475 0 0 0 3.36-1.669c4.745-3.466 4.13-8.37 2.77-11.862a1.08 1.08 0 0 1 .026-.847c.128-.257.359-.463.641-.565 4.053-1.284 6.515-3.364 7.336-6.137.744-2.542.692-4.493-.154-5.777a1.053 1.053 0 0 1 .128-1.335c2.155-2.26 3.283-5.058 3.155-7.883-.128-2.644-1.308-5.16-3.309-6.881-.333-.308-.461-.796-.308-1.207.18-.436.59-.693 1.052-.667.616 0 6.053.231 7.413.462l.718.128c11.517 1.977 18.673 2.902 20.263-2.824.744-2.67.411-5.135-.949-7.318-2.565-4.082-8.875-7.189-17.698-8.755-24.931-4.416-48.786 5.032-59.481 16.638l.051-.026Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M152.871 182.247c-.282 0-.564-.103-.769-.334-6.669-6.984-20.571-8.447-20.699-8.473-.59-.051-1.026-.59-.975-1.181a1.09 1.09 0 0 1 1.18-.976c.59.052 14.8 1.567 22.033 9.115.41.437.385 1.104-.026 1.515a1.067 1.067 0 0 1-.744.308v.026ZM153.077 198.551c-.334 0-.667-.154-.872-.462-6.233-8.704-25.65-12.53-25.855-12.556a1.045 1.045 0 0 1-.847-1.258c.103-.59.667-.975 1.257-.847.821.154 20.469 4.031 27.189 13.429.333.487.231 1.155-.257 1.489-.179.128-.41.205-.615.205ZM145.33 212.108c-.307 0-.59-.128-.82-.36-7.028-8.139-24.804-12.093-24.983-12.119a1.066 1.066 0 0 1-.821-1.283c.128-.591.693-.95 1.283-.822.769.154 18.647 4.134 26.162 12.838.385.462.334 1.13-.102 1.515-.206.18-.462.257-.693.257l-.026-.026ZM135.096 227.386c-3.642 0-9.387-1.002-14.389-6.317a1.063 1.063 0 0 1 .051-1.515 1.06 1.06 0 0 1 1.513.052c7.054 7.471 15.647 5.417 16.006 5.34a1.072 1.072 0 0 1 1.308.771c.154.565-.205 1.155-.77 1.309-.153.026-1.564.385-3.693.385l-.026-.025Z"
			/>
			<path
				fill="#fff"
				d="M253.52 325.699c-.59 0-1.051-.462-1.077-1.053-.718-28.269 17.262-67.527 17.442-67.912a1.078 1.078 0 1 1 1.949.924c-.179.385-17.955 39.181-17.236 66.937 0 .591-.462 1.079-1.052 1.104h-.026ZM231.616 130.511l-6.362-1.259-11.157 7.626 91.877 10.014-27.651-45.806-11.131 2.747-.052 19-21.007-14.635-.718 12.479-12.44-5.495-1.359 15.329Z"
			/>
			<path
				fill="#D894F8"
				d="M282.402 99.777c-2.078.667-3.001 3.107-1.873 4.981l25.419 42.134-92.364-10.219c-4.848-.539-9.234 2.927-9.849 7.754L190.192 250.34c-.667 5.29 3.411 10.014 8.746 10.091l91.928 1.284a8.945 8.945 0 0 0 9.003-7.96l11.337-101.574c.334-3.029-.256-6.085-1.744-8.755l-23.11-42.057a3.367 3.367 0 0 0-3.976-1.592h.026Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M305.974 147.97h-.129l-91.876-10.013c-.436-.052-.821-.36-.924-.796a1.121 1.121 0 0 1 .436-1.156l11.158-7.626a1.15 1.15 0 0 1 .821-.179l5.181 1.001 1.257-14.122c.025-.333.231-.667.513-.821.308-.18.667-.206.974-.052l11.03 4.879.615-10.938c0-.385.257-.745.59-.899.359-.18.77-.128 1.078.077l19.314 13.454.051-16.946c0-.488.333-.924.821-1.052l11.132-2.748c.461-.103.923.077 1.18.488l27.65 45.806c.205.359.205.796 0 1.13a1.075 1.075 0 0 1-.898.487l.026.026Zm-88.85-11.836 86.798 9.474-26.112-43.264-9.567 2.362-.051 18.153c0 .411-.231.77-.59.95-.359.18-.77.154-1.103-.077l-19.417-13.531-.59 10.553c0 .359-.205.667-.513.847-.307.18-.667.205-1 .077l-11.081-4.878-1.128 12.812 49.119 8.088c.59.103.974.642.897 1.232a1.091 1.091 0 0 1-1.231.899l-50.093-8.242h-.077l-5.874-1.155-8.387 5.725v-.025Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M286.531 130.819c-.333 0-.641-.154-.872-.437l-19.34-25.907a1.094 1.094 0 0 1 .206-1.515 1.091 1.091 0 0 1 1.513.206l19.34 25.907a1.094 1.094 0 0 1-.205 1.515 1.01 1.01 0 0 1-.642.205v.026ZM289.738 139.112c-.129 0-.257 0-.385-.077l-44.323-17.357c-.564-.205-.82-.847-.615-1.386.205-.565.846-.822 1.385-.616l35.191 13.788-14.466-9.731a1.073 1.073 0 0 1-.282-1.49 1.07 1.07 0 0 1 1.487-.282l22.597 15.2c.462.308.616.899.36 1.387-.18.359-.565.564-.949.564Z"
			/>
			<path
				fill="#fff"
				d="M325.39 135.955c-8.233-14.995-17.467-18.59-23.777-13.711-6.31 4.878-1.59 15.148 2.514 21.593 2.36 3.697 6.002 8.293 6.438 13.377-7.079-5.7-16.98-14.122-23.367-6.29-5.361 6.598 5.361 15.097 5.361 15.097s-10.439-6.419-15.364 1.412c-5.976 9.5 7.361 19.103 7.361 19.103s-9.849-6.804-14.056-1.284c-3.437 4.519-.872 11.786 6.464 17.691 5.386 4.314 17.955 12.453 17.955 12.453-1.873 6.008.744 11.631.744 11.631l-10.722 17.716c-9.439 15.611-2.847 35.998 13.979 43.085 16.39 6.932 35.14-1.926 40.27-18.975 6.489-21.568 13.799-47.86 14.005-57.617.692-32.069-19.981-61.006-27.83-75.307l.025.026Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M310.539 291.268c-4.104 0-8.182-.821-12.029-2.439-8.311-3.492-14.646-10.424-17.442-19a31.112 31.112 0 0 1 2.95-25.625l10.439-17.254c-.59-1.541-2.078-6.085-.795-11.092-2.693-1.746-12.645-8.293-17.365-12.068-7.772-6.239-10.491-14.121-6.643-19.18 2.334-3.081 6.002-2.952 9.182-2.002-3.514-4.288-6.053-10.168-2.565-15.74 2.899-4.621 7.695-4.929 11.722-3.902-1.564-1.952-3.052-4.417-3.334-7.036-.231-2.105.333-4.005 1.693-5.674 6.335-7.78 15.697-1.207 22.546 4.262-.924-2.901-2.745-5.648-4.412-8.139-.462-.668-.872-1.335-1.283-1.951-3.95-6.188-9.464-17.434-2.257-23.032 2.565-1.977 5.566-2.696 8.696-2.028 5.796 1.207 11.568 6.753 16.672 16.073 1.18 2.157 2.616 4.596 4.309 7.42 9.054 15.303 24.239 40.902 23.649 68.427-.205 9.988-7.772 36.999-14.056 57.899a31.047 31.047 0 0 1-29.702 22.107l.025-.026Zm-35.319-107.12c-1.488 0-2.873.462-3.848 1.746-3.103 4.083-.513 10.733 6.285 16.202 5.283 4.236 17.723 12.298 17.852 12.376.41.256.59.77.436 1.232-1.745 5.546.667 10.81.692 10.861.154.334.128.693-.051 1.001l-10.722 17.717a28.874 28.874 0 0 0-2.744 23.853 28.825 28.825 0 0 0 16.21 17.69c7.516 3.184 16.083 3.004 23.47-.462a28.957 28.957 0 0 0 15.364-17.819c8.977-29.784 13.799-49.606 13.979-57.309.564-26.908-13.825-51.223-23.367-67.296-1.693-2.85-3.129-5.289-4.335-7.472-4.719-8.627-10.131-13.942-15.235-14.995-2.54-.539-4.874 0-6.952 1.618-6.078 4.724-.102 15.662 2.771 20.156.384.616.82 1.258 1.256 1.9 2.206 3.312 4.976 7.446 5.336 11.939.025.436-.18.847-.565 1.053a1.094 1.094 0 0 1-1.18-.103l-.641-.514c-8.592-6.958-16.108-12.196-21.212-5.931-1 1.207-1.385 2.542-1.231 4.083.513 4.75 6.361 9.448 6.412 9.5.436.359.539 1.001.205 1.463a1.088 1.088 0 0 1-1.436.308c-.385-.231-9.593-5.777-13.876 1.053-5.361 8.55 6.951 17.562 7.079 17.665.487.334.59 1.001.256 1.489-.333.488-1 .591-1.487.257-.077-.051-4.771-3.261-8.721-3.261Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M307.205 205.382a1.1 1.1 0 0 1-.718-.283c-15.595-13.993-22.367-17.588-22.418-17.613-.539-.283-.744-.925-.462-1.438.282-.539.924-.745 1.437-.462.282.154 7.028 3.697 22.879 17.947.436.385.487 1.079.077 1.515a1.092 1.092 0 0 1-.795.359v-.025ZM327.955 174.391a1.07 1.07 0 0 1-.743-.308c-9.747-9.423-17.288-15.996-17.365-16.073-.436-.385-.488-1.079-.103-1.515a1.085 1.085 0 0 1 1.513-.103c.077.077 7.644 6.676 17.442 16.125.436.411.436 1.104.026 1.515a1.01 1.01 0 0 1-.77.333v.026ZM318.055 188.385c-.283 0-.565-.103-.77-.309-14.825-15.02-25.188-21.079-25.29-21.131-.513-.308-.693-.95-.385-1.463.308-.514.949-.694 1.462-.385.436.256 10.696 6.239 25.726 21.49a1.085 1.085 0 0 1 0 1.515 1.066 1.066 0 0 1-.743.308v-.025ZM173.365 37.282a1.07 1.07 0 0 1-1.026-.745 1.086 1.086 0 0 1 .693-1.36l74.384-24.778c.564-.18 1.18.128 1.359.693a1.084 1.084 0 0 1-.692 1.36L173.699 37.23s-.231.052-.334.052Z"
			/>
			<path
				fill="#fff"
				d="M159.566 31.684c2.488-7.626 10.696-11.785 18.314-9.269 7.618 2.49 11.773 10.707 9.259 18.333-2.488 7.625-10.696 11.785-18.314 9.269-7.617-2.49-11.773-10.707-9.259-18.333Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M173.34 51.814a15.93 15.93 0 0 1-4.848-.77c-8.157-2.67-12.645-11.503-9.952-19.694 1.308-3.954 4.053-7.19 7.772-9.064 3.719-1.874 7.951-2.208 11.901-.898 8.157 2.67 12.645 11.503 9.952 19.693-2.154 6.573-8.259 10.733-14.825 10.733Zm0-29.065a13.24 13.24 0 0 0-6.054 1.463 13.26 13.26 0 0 0-6.694 7.806c-2.309 7.035 1.539 14.66 8.593 16.971 7.053 2.311 14.645-1.54 16.954-8.6 2.308-7.036-1.539-14.662-8.593-16.973a13.212 13.212 0 0 0-4.206-.667ZM301.049 61.571c-.257 0-.487-.077-.693-.257l-54.864-47.526c-.462-.385-.487-1.078-.103-1.515a1.087 1.087 0 0 1 1.514-.102l54.864 47.526c.462.385.487 1.078.103 1.515-.205.256-.513.36-.821.36Z"
			/>
			<path
				fill="#0719F5"
				d="M233.873 8.935c2.231-6.804 9.541-10.527 16.339-8.293 6.797 2.234 10.516 9.551 8.284 16.355-2.231 6.805-9.541 10.527-16.338 8.294-6.798-2.234-10.517-9.552-8.285-16.356Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M319.953 64.37a1.042 1.042 0 0 1-.642-.668 1.051 1.051 0 0 1 .667-1.361l66.741-23.185a1.05 1.05 0 0 1 1.359.667 1.051 1.051 0 0 1-.667 1.361l-66.74 23.185a1.142 1.142 0 0 1-.718 0Z"
			/>
			<path
				fill="#fff"
				d="M376.305 32.993c3.975-5.956 12.004-7.574 17.954-3.62 5.951 3.98 7.567 12.016 3.617 17.973-3.976 5.957-12.004 7.575-17.955 3.62-5.95-3.98-7.566-12.016-3.616-17.973Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M382.179 53.329c-.975-.36-1.95-.847-2.848-1.464-6.438-4.288-8.207-13.017-3.898-19.488 4.283-6.444 13.004-8.216 19.468-3.902 6.438 4.288 8.208 13.017 3.898 19.488-3.667 5.546-10.618 7.6-16.595 5.366h-.025Zm-4.977-19.745c-3.616 5.47-2.154 12.864 3.309 16.484 5.464 3.62 12.851 2.157 16.467-3.312 3.643-5.47 2.155-12.864-3.308-16.484-5.464-3.62-12.851-2.157-16.468 3.312Z"
			/>
			<path
				fill="#D894F8"
				d="M539 164.583h-18.75s-.102-.026-.102-.077A50.455 50.455 0 0 0 514.761 151l13.184-13.197-17.211-17.229-12.876 12.89a49.661 49.661 0 0 0-13.876-6.034v-17.948H459.64v17.614a50.304 50.304 0 0 0-14.415 5.829L432.913 120.6l-17.21 17.229 12.26 12.273a50.448 50.448 0 0 0-5.951 14.507h-17.39v24.366h17.621a50.598 50.598 0 0 0 6.079 14.122l-12.619 12.632 17.21 17.229 12.928-12.941a49.804 49.804 0 0 0 13.825 5.469v18.564h24.341v-18.898a50.062 50.062 0 0 0 13.287-5.648l13.466 13.48 17.211-17.229-13.518-13.531a51.32 51.32 0 0 0 5.515-13.146c0-.052.051-.077.103-.077h18.98v-24.367l-.051-.051Zm-67.228 39.387c-15.004 0-27.188-12.171-27.188-27.217s12.158-27.216 27.188-27.216c15.031 0 27.189 12.17 27.189 27.216 0 15.046-12.158 27.217-27.189 27.217Z"
			/>
			<path
				fill="#0719F5"
				d="M329.033 61.57c0 9.116-7.362 16.485-16.467 16.485-9.106 0-16.467-7.37-16.467-16.484 0-9.115 7.361-16.484 16.467-16.484 9.105 0 16.467 7.369 16.467 16.484ZM468.823 259.173l-12.568 4.673s-.077 0-.103-.026c-1.975-2.927-4.309-5.52-6.977-7.702l5.541-12.12-15.826-7.266-5.412 11.862c-3.514-.718-7.131-.95-10.799-.59l-4.463-12.042-16.313 6.085 4.386 11.785a35.255 35.255 0 0 0-8.208 7.498l-11.337-5.187-7.259 15.842 11.26 5.161c-.692 3.646-.846 7.42-.359 11.195l-11.644 4.339 6.078 16.33 11.799-4.391c2.129 3.081 4.72 5.726 7.593 7.96l-5.31 11.631 15.826 7.266 5.438-11.888c3.462.591 7.028.694 10.618.231l4.617 12.428 16.314-6.086-4.694-12.658a35.255 35.255 0 0 0 7.489-7.112l12.363 5.674 7.259-15.842-12.44-5.7c.616-3.312.77-6.753.411-10.193 0-.026 0-.077.051-.077l12.722-4.724-6.079-16.33.026-.026Zm-34.961 43.88c-10.465 3.903-22.084-1.438-25.983-11.914-3.898-10.475 1.437-22.106 11.902-26.009 10.465-3.903 22.084 1.438 25.983 11.913 3.899 10.476-1.437 22.107-11.902 26.01Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M119.655 225.692h-.128c-.59-.077-1-.617-.923-1.207.743-5.726 5.719-7.908 8.233-8.268a1.072 1.072 0 0 1 1.206.925 1.072 1.072 0 0 1-.924 1.206c-.231.026-5.694.899-6.412 6.394-.077.539-.539.95-1.077.95h.025ZM386.206 124.657c-.283 0-.539-.103-.77-.309l-14.928-14.943a1.087 1.087 0 0 1 0-1.515 1.082 1.082 0 0 1 1.513 0l14.928 14.944c.411.41.411 1.104 0 1.514a1.083 1.083 0 0 1-.769.309h.026Z"
			/>
			<path
				fill="#000"
				className="fill-[#000] dark:fill-muted"
				d="M371.277 124.657c-.282 0-.538-.103-.769-.309a1.086 1.086 0 0 1 0-1.514l14.928-14.944a1.082 1.082 0 0 1 1.513 0 1.085 1.085 0 0 1 0 1.515l-14.928 14.943a1.083 1.083 0 0 1-.769.309h.025Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h539v415H0z" />
			</clipPath>
		</defs>
	</svg>
)
